import React from "react"
import { css } from "@emotion/core"
import { Image, GradientLinkButton, Animate } from "../common"

import { useWindowWidth } from "../../hooks/useWindowWidth"

import WhoareWeImage from "../../images/home/who.jpg"

const WhoareweRow = () => {
  const width = useWindowWidth()
  return (
    <div
      css={css`
        width: 100%;
        height: 1024px;
        position: relative;

        @media (max-width: 1000px) {
          display: flex;
          justify-content: center;
          background: url(${WhoareWeImage});
          background-size: cover;
          background-position-x: center;
          background-position-y: top;
          padding-top: 200px;
          padding-bottom: 50px;
          height: fit-content;
        }
      `}
    >
      {width >= 1000 && (
        <Animate type="reveal" cascade left distance="20px">
          <Image
            src={WhoareWeImage}
            css={theme => css`
              max-height: 90%;
              position: absolute;
              box-shadow: ${theme.constants.boxShadow};
            `}
          />
        </Animate>
      )}
      <Animate type="reveal" right distance="20px">
        <div
          css={theme => css`
            position: absolute;
            width: 50%;
            top: 200px;
            background: ${theme.colors.veryDarkgray};
            color: ${theme.colors.white};
            margin-left: 45%;
            padding: 75px;
            box-shadow: ${theme.constants.boxShadow};

            @media (max-width: 1000px) {
              top: 0px;
              position: inherit;
              width: 90%;
              height: max-content;
              margin-left: 0px;
              padding: 50px;
            }

            @media (max-width: 600px) {
              padding: 30px;
            }
          `}
        >
          <div
            css={theme => css`
              font-size: ${theme.constants.semiFontSize}px;
              font-weight: ${theme.constants.demi};
              width: 70%;
              margin-bottom: 50px;

              @media (max-width: 1350px) {
                width: 100%;
              }

              @media (max-width: 600px) {
                font-size: ${theme.constants.mediumFontSize}px;
              }
            `}
          >
            Who are we?
          </div>
          <div
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.light};
              text-align: left;
              margin-bottom: 60px;

              @media (max-width: 600px) {
                font-size: ${theme.constants.smallFontSize}px;
                margin-bottom: 30px;
              }
            `}
          >
            an iOS, an Android, and a full stack developer walk into a bar...
          </div>
          <div
            css={theme => css`
              font-size: ${theme.constants.infoDescriptionTextSize}px;
              color: ${theme.colors.darkGray};
              text-align: left;
              line-height: 2;
              margin-bottom: 20px;
            `}
          >
            We are creative thinkers, tech enthusiast and software engineers. We
            are a software engineering company helping startups and enterprises
            by creating custom software solutions. Our mission is to help you
            grow your business.
          </div>
          <GradientLinkButton
            to="/about"
            centered={width <= 880}
            css={css`
              width: 300px;

              @media (max-width: 1000px) {
                margin: 0 auto;
              }

              @media (max-width: 880px) {
                width: 90%;
              }
              @media (max-width: 600px) {
                width: 100%;
              }
            `}
          >
            see the full story
          </GradientLinkButton>
        </div>
      </Animate>
    </div>
  )
}

export default WhoareweRow
