import React, { useState } from "react"
import { css } from "@emotion/core"
import { Image, LinkButton, Link } from "../common"

const ProjectRow = ({
  image,
  phonePreview,
  link,
  title,
  description,
  flip,
  top = 100,
}) => {
  const [hovered, setHovered] = useState(false)

  return (
    <div
      css={theme => css`
        width: 100%;
        height: 770px;
        position: relative;

        &:hover {
          img:first-of-type {
            opacity: 1;
            filter: grayscale(0%);
          }

          a:first-of-type {
            color: ${theme.colors.darkBlue};
          }

          div:first-of-type {
            color: ${theme.colors.darkBlue};
          }
        }

        @media (max-width: 1000px) {
          height: 1000px;
        }

        @media (max-width: 880px) {
          height: 770px;
        }

        @media (max-width: 600px) {
          height: 550px;
        }
      `}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        css={css`
          position: absolute;
          top: ${top}px;
          width: 35%;
          margin-left: 0;
          ${!flip && "right: 0;"}
          z-index: 100;

          @media (max-width: 1000px) {
            position: initial;
            width: 100%;
            padding: 0 20px;
          }
        `}
      >
        <Link
          to={link}
          css={theme => css`
            text-decoration: none;
            color: ${theme.colors.veryDarkgray};
          `}
        >
          <div
            css={theme => css`
              font-size: ${theme.constants.largeFontSize}px;
              font-weight: ${theme.constants.demi};
              margin-bottom: 40px;
              text-align: ${flip ? "left" : "right"};

              @media (max-width: 1000px) {
                font-size: ${theme.constants.mediumFontSize}px;
                margin-bottom: 20px;
                text-align: left;
              }
            `}
          >
            {title}
          </div>
          <div
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.light};
              color: ${theme.colors.veryDarkgray};
              text-align: left;
              margin-bottom: 40px;
              text-align: ${flip ? "left" : "right"};

              @media (max-width: 1000px) {
                font-size: ${theme.constants.smallFontSize}px;
                margin-bottom: 15px;
                text-align: left;
              }
            `}
          >
            {description}
          </div>
        </Link>
        <div
          css={css`
            display: flex;
            ${!flip && "justify-content: flex-end"};

            @media (max-width: 1000px) {
              justify-content: flex-start;
            }
          `}
        >
          <LinkButton
            arrowVariant={hovered ? "darkblue" : "blue"}
            pure
            css={css`
              width: 260px;
            `}
            to={link}
          >
            see full project
          </LinkButton>
        </div>
      </div>
      <Link
        to={link}
        css={theme => css`
          text-decoration: none;
          color: ${theme.colors.veryDarkgray};

          @media (max-width: 1000px) {
            display: flex;
            flex-direction: column;
          }
        `}
      >
        <Image
          src={image}
          css={theme => css`
            position: absolute;
            height: 60%;
            box-shadow: ${theme.constants.boxShadow};
            ${flip ? "margin-right: 50px;" : "margin-left: 50px;"}
            ${flip && "right: 0px;"}

            opacity: 0.5;
            filter: grayscale(100%);
            transition: 0.5s ease-in-out;

            @media (max-width: 1000px) {
              width: 100%;
              margin-right: 0px;
              height: auto;
              margin-left: 0px;
            }
          `}
        />
        <Image
          src={phonePreview}
          css={css`
            height: 100%;
            position: absolute;
            top: 40px;
            ${flip ? "right: -120px;" : "left: -120px;"}

            @media (max-width: 1200px) {
              ${flip ? "right: 0px;" : "left: 0px;"}
            }

            @media (max-width: 1000px) {
              margin-top: 140px;
              ${flip ? "align-self: flex-end;" : "align-self: flex-start"};
              width: 70%;
              height: auto;
            }

            @media (max-width: 880px) {
              margin-top: 200px;
            }

            @media (max-width: 600px) {
              margin-top: 200px;
              width: 50%;
            }

            @media (max-width: 375px) {
              top: 100px;
            }
          `}
        />
      </Link>
    </div>
  )
}
export default ProjectRow
