import React, { useState } from "react"
import { css } from "@emotion/core"
import { LinkButton, OutlineLinkButton } from "../common"

import { useWindowWidth } from "../../hooks/useWindowWidth"

const Column = ({ title, description, to }) => {
  const width = useWindowWidth()
  const [selected, setSelected] = useState(false)

  return (
    <div
      css={theme => css`
        color: ${selected ? theme.colors.black : theme.colors.white};
        padding: 35px;
        display: flex;
        flex-direction: column;
        min-height: 500px;
        transition: transform 0.5s ease-in-out, background 0.5s;
        @media (min-width: 1001px) {
          &:hover {
            background: ${theme.colors.white};
            transform: translateY(-20px);
          }
        }

        @media (max-width: 1000px) {
          background: transparent;
          color: ${theme.colors.white};
          min-height: 300px;
        }

        @media (max-width: 600px) {
          padding: 20px;
        }
      `}
      onMouseEnter={() => setSelected(true)}
      onMouseLeave={() => setSelected(false)}
    >
      <div
        css={theme => css`
          font-size: ${theme.constants.mediumFontSize}px;
          font-weight: ${theme.constants.demi};
          margin-bottom: 30px;
        `}
      >
        {title}
      </div>
      <div
        css={theme => css`
          font-size: ${theme.constants.extraSmallFontSize}px;
          font-weight: ${theme.constants.light};
          ${selected && `color: ${theme.colors.darkGray};`}
          line-height: 2;
          margin-bottom: 20px;

          flex: 1;

          @media (max-width: 1000px) {
            color: ${theme.colors.white};
          }
        `}
      >
        {description}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          @media (max-width: 1000px) {
            justify-content: center;
          }
        `}
      >
        {width <= 1000 ? (
          <OutlineLinkButton
            css={css`
              width: 100%;
              font-size: 12px;
            `}
            to={to}
            centered
          >
            read more
          </OutlineLinkButton>
        ) : (
          selected && (
            <LinkButton
              css={css`
                width: 150px;
                font-size: 12px;
              `}
              to={to}
            >
              read more
            </LinkButton>
          )
        )}
      </div>
    </div>
  )
}

export default Column
