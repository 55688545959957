import React from "react"
import { css, keyframes } from "@emotion/core"

import { isBrowser } from "../../utils/isBrowser"
import { useWindowWidth } from "../../hooks/useWindowWidth"

import { Image, OutlineLinkButton, OutlineExternalLink } from "../common"

import DownArrowImage from "../../images/common/arrow_down_white.svg"

const MainHero = () => {
  const width = useWindowWidth()

  const bounce = keyframes`
    0% {
      transform: translateX(-50%) translateY(0px);
    }
    5% {
      transform: translateX(-50%) translateY(10px);
    }
    10% {
      transform: translateX(-50%) translateY(0px);
    }
    100% {
      transform: translateX(-50%) translateY(0px);
    }
  `

  const scrollTo = () => {
    let scrollHeight = 900
    if (width <= 1000) {
      scrollHeight = 700
    }
    return (
      isBrowser() && window.scrollTo({ top: scrollHeight, behavior: "smooth" })
    )
  }

  return (
    <div
      css={theme => css`
        position: relative;
        padding: ${theme.constants.pageMargin};
        padding-left: 165px;
        height: ${theme.constants.heroHeight - theme.constants.headerHeight}px;
        top: ${theme.constants.headerHeight}px;

        @media (max-width: 1200px) {
          padding: ${theme.constants.mediumpageMargin};
        }
        @media (max-width: 1000px) {
          height: calc(
            ${theme.constants.mediumheroHeight}px -
              ${theme.constants.headerHeight}px
          );
          padding: ${theme.constants.smallpageMargin};
        }
      `}
    >
      <div
        css={theme => css`
          font-size: ${theme.constants.heroTitleSize}px;
          font-weight: ${theme.constants.demi};
          color: ${theme.colors.white};
          width: 85%;
          padding-top: 120px;
          padding-bottom: 40px;

          @media (max-width: 1000px) {
            width: 100%;
            text-align: center;
            font-size: ${theme.constants.semiFontSize}px;
            padding-top: 50px;
          }

          @media (max-width: 880px) {
            font-size: ${theme.constants.mediumFontSize}px;
            padding-top: 100px;
          }
        `}
      >
        We take care of your product development,
      </div>
      <div
        css={theme => css`
          font-size: ${theme.constants.heroDescriptionSize}px;
          font-weight: ${theme.constants.extraLight};
          color: ${theme.colors.white};
          width: 60%;
          padding-bottom: 40px;

          @media (max-width: 1000px) {
            font-size: ${theme.constants.smallFontSize}px;
            width: 100%;
            text-align: center;
          }

          @media (max-width: 880px) {
            font-size: ${theme.constants.extraSmallFontSize}px;
            margin-bottom: 0px;
          }
        `}
      >
        so you can focus on growing your business.
      </div>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
        `}
      >
        <OutlineExternalLink
          css={css`
            width: 370px;
            margin-bottom: 50px;

            @media (max-width: 1000px) {
              margin: 0 auto;
              margin-bottom: 40px;
            }

            @media (max-width: 880px) {
              width: 100%;
              margin-bottom: 50px;
            }

            @media (max-width: 500px) {
              margin-bottom: 10px;
            }
          `}
          to="https://calendly.com/hello-deverest/15min"
        >
          BOOK MY FREE CONSULTATION
        </OutlineExternalLink>
        <OutlineLinkButton
          centered={width <= 880}
          css={css`
            width: 300px;
            @media (max-width: 1000px) {
              margin: 0 auto;
            }

            @media (max-width: 880px) {
              width: 100%;
            }
          `}
          to="/projects"
        >
          see our projects
        </OutlineLinkButton>
      </div>
      <div
        css={theme => css`
          border: 2px solid ${theme.colors.white};
          height: 30px;
          width: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 30px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          background: ${theme.colors.transparentWhite};
          cursor: pointer;

          @media (max-width: 1000px) {
            bottom: 20px;
          }

          animation: ${bounce} 3s infinite;

          &:hover {
            background: transparent;
          }
        `}
        role="button"
        tabIndex="0"
        onKeyPress={scrollTo}
        onClick={scrollTo}
      >
        <Image
          css={css`
            height: 12px;
          `}
          src={DownArrowImage}
        />
      </div>
    </div>
  )
}
export default MainHero
