import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/Layout"
import ProjectRow from "../components/rows/ProjectRow"
import WhoareweRow from "../components/rows/WhoareweRow"
import Column from "../components/infocolumns/Column"
import { Arrow, ProjectsBox, Animate } from "../components/common"

import MainHero from "../components/heros/MainHero"

import WorknImage from "../images/home/workn_color.jpg"
import WorknPhoneImage from "../images/home/workn_phone.png"

import AllyoucanmoveImage from "../images/home/allyoucanmove_color.jpg"
import AllyoucanmovePhoneImage from "../images/home/allyoucanmove_phone.png"

import BackgroundImage from "../images/home/bg.jpg"

const IndexPage = () => (
  <Layout withHero heroContent={MainHero} heroImage={BackgroundImage}>
    <div
      css={theme => css`
        margin: ${theme.constants.pageMargin};

        @media (max-width: 1200px) {
          margin: ${theme.constants.mediumpageMargin};
        }

        @media (max-width: 1000px) {
          margin: 0px;
        }
      `}
    >
      <ProjectRow
        title="WorkN"
        description="WorkN is a transformative workforce platform for staffing firms and companies to work on-demand and do mobile scheduling"
        link="/projects/workn"
        image={WorknImage}
        phonePreview={WorknPhoneImage}
        flip
        top={80}
      />
      <Animate type="fade" bottom distance="20px">
        <ProjectRow
          title="All You Can Move"
          description="Gym goers can find the nearest facilities and stay connected to the All You Can Move community"
          link="/projects/aycm"
          image={AllyoucanmoveImage}
          phonePreview={AllyoucanmovePhoneImage}
          top={50}
        />
      </Animate>
      <ProjectsBox />
      <Arrow top={3300} left={50} flip />
      <WhoareweRow />
      <Arrow top={4550} right={50} />
    </div>
    <div
      css={theme => css`
        margin-top: 200px;
        margin-bottom: 200px;
        ${theme.gradients.blue(theme)};
        display: grid;
        padding: 260px 160px 70px 160px;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

        @media (max-width: 1350px) {
          padding-left: 50px;
          padding-right: 50px;
        }
        @media (max-width: 1000px) {
          margin-top: 0px;
          padding: 0px;
          grid-template-columns: 1fr;
          margin-bottom: 50px;
        }
      `}
    >
      <Column
        title="Startup product development"
        to="/services"
        description="Are you looking for a team to develop an MVP for your business? Book a 30 minute call with us and we will help you choose the right solution for your idea."
      />
      <Column
        title="Mobile app development"
        to="/services"
        description="Developing mobile apps, we aim for the highest quality possible including an exceptional design and smooth user experience. We aspire to find the most suitable aid to your company’s business processes by improving loyalty programs, creating taylor made applications."
      />
      <Column
        title="Web development"
        to="/services"
        description="With a focus on user experience, we develop customized, safe and scalable websites and web applications. A main objective of ours is to identify a solution suitable for your business by focusing on creating and improving loyalty programs, creating CMS systems and custom admin sites."
      />
    </div>
  </Layout>
)

export default IndexPage
